<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="일자"
            name="srchDts"
            :range="true"
            v-model="searchParam.srchDts"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="보수사항"
      tableId="grid"
      :columns="grid.columns"
      :data="grid.data"
      :usePaging="true"
      :columnSetting="false"
      :filtering="true"
      :isExcelDown="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable && grid.data.length > 0" label="SEMS 다운로드" icon="download" @btnClicked="downLoadSems"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'air-sems-01',
  data() {
    return {
      editable: true,
      searchParam: {
        plantCd: null,
        srchYear: '',
        srchDts: [],
      },
      grid: {
        columns: [
          // {
          //   name: 'startDt',
          //   field: 'startDt',
          //   label: '등록일자',
          //   align: 'center',
          //   sortable: false,
          // },
          {
            name: 'envAirMstOutletName',
            field: 'envAirMstOutletName',
            label: '배출구일련번호',
            align: 'center',
            sortable: false,
          },
          {
            name: 'envAirMstOutletSems',
            field: 'envAirMstOutletSems',
            label: '허가증상배출구번호',
            align: 'center',
            sortable: false,
          },
          {
            name: 'envAirMstOutletNm',
            field: 'envAirMstOutletNm',
            label: '배출구명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'envAirMstPreventiveNo',
            field: 'envAirMstPreventiveNo',
            label: '방지시설일련번호',
            align: 'center',
            sortable: false,
          },
          {
            name: 'envAirMstPreventiveNum',
            field: 'envAirMstPreventiveNum',
            label: '사내고유방지시설번호',
            align: 'center',
            sortable: false,
          },
          {
            name: 'envAirMstPreventiveName',
            field: 'envAirMstPreventiveName',
            label: '방지시설명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'startDt',
            field: 'startDt',
            label: '시작일자',
            align: 'center',
            sortable: false,
          },
          {
            name: 'endDt',
            field: 'endDt',
            label: '마침일자',
            align: 'center',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '보수자',
            align: 'center',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '보수명세',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
      saveUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.air.sems.prehis.list.url;
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param =this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    downLoadSems() {
      let thisVue = this;
      this.$http.url = selectConfig.env.air.sems.prehis.list.url + '/excel';
      this.$http.param =this.searchParam;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
          var blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "SEMS보수사항엑셀양식.xlsx");
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "SEMS보수사항엑셀양식.xlsx";
            link.click();
          }
      },);
    },
  }
};
</script>
